import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AppSetting } from 'src/app/shared/appSetting';
import { catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  /**
   * Append bearer token to auth settings
   * @param settings
   */
  constructor(private settings: AppSetting, private cookieService: CookieService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If token present, add bearer token
    var headers = req.headers;
    if (this.settings.token) {
      headers = headers.set('Authorization', this.settings.token);
    }
    else if (this.cookieService.check('mml-auth-token')){
      var token = this.cookieService.get('mml-auth-token')
      headers = headers.set('Authorization', 'Bearer ' + token);
    }
    headers = headers.set('x-cv-client-id', 'esignportal');
    // Create headers element
    // Clone request, add headers
    const cloneReq = next.handle(req.clone({ headers: headers }));
    // Return request, handle errors globally here
    return cloneReq.pipe(catchError(error => {
      // If forbidden error, end session
      if (error.status === 401 || error.status === 403) {
      }
      // Catch and rethrow error
      return throwError(error);
    }) as any);
  }
}
