import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { AppSetting } from "./appSetting";

@Injectable()
export class AppConfigService {
    constructor(private http: HttpClient) { }

    public GetAppSettings(appSetting: AppSetting): Promise<any> {
        return new Promise<boolean>((resolve: (a: boolean) => void): void => { 
            this.http.get('../../env.json')
            .pipe(map((x: AppSetting) => { 
                appSetting.apiUrl = x.apiUrl; 
                resolve(true); 
            }))
            .subscribe(); 
        });
    }
}
