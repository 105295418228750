import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({providedIn:'root'})
export class UserService {
    private userLoggedInState = new BehaviorSubject<boolean>(false);
    userLoggedIn$ = this.userLoggedInState.asObservable();
    
    /*get userLoggedIn$()
    { return this.userLoggedInState.asObservable();}*/

    constructor() { }

    public setUserLoggedIn(value: boolean) {
        this.userLoggedInState.next(value);
    }
}
