import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ChangeDetectorRef  } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from './shared/api.service';
import { AuthenticationService } from './shared/authentication.service';
import { UserService } from './user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  errorMessage: string;
  invalidSSOConfiguration: string;
  isUserLoggedIn$ = this.userService.userLoggedIn$;

  constructor(private route: ActivatedRoute, private userService: UserService, private apiService: ApiService, 
    private authService: AuthenticationService, private sanitizer: DomSanitizer, private cdref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.authService.getCompanyLogo();
    this.route.queryParams.subscribe(queryParam => {
      this.invalidSSOConfiguration = queryParam['invalidSSOConfiguration'];
      if (this.invalidSSOConfiguration === "1")
      {
        console.warn(`Invalid SSO Configuration`);
        this.errorMessage = `Invalid SSO Configuration`;
      }
    })
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  get companyLogo() {
    return this.sanitizer.bypassSecurityTrustUrl(this.authService.companyLogo);
  }

  onLogout(): void {
    if (this.authService.checkIfLOUserIsLoggedIn()) {
      this.authService.clearCookie('mml-auth-token');
      this.userService.setUserLoggedIn(false);
    }
    else {
      this.apiService.SignOutSSO().subscribe(
        (redirectUrl) => {
          this.userService.setUserLoggedIn(false);
          window.location.href = redirectUrl;
        },
        (error: HttpErrorResponse) => {
          console.warn(`Error while SSO singing out`, error);
          this.errorMessage = `An error occurred while singing out: ${error.message}`;
        });
    }  
  }
}
