import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { filter, take } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/api.service';
import { UserService } from 'src/app/user.service';
import { ConsentStatusEnum, ESignerRoleEnum } from 'src/app/models/enums';
import { AuthenticationService } from 'src/app/shared/authentication.service';
import { AppSetting } from 'src/app/shared/appSetting';
import { ILoanViewModel } from 'src/app/models/viewmodels';
import { MatDialog } from '@angular/material/dialog';
import { ConsentPopUpComponent } from '../modals/consent-pop-up/consent-pop-up.component';


export class BorrowerPortalSSOInfo{
  signUrl: string;
  lsid: string;
}

@Component({
  selector: 'app-borrower-portal',
  templateUrl: './borrower-portal.component.html',
  styleUrls: ['./borrower-portal.component.scss']
})
export class BorrowerPortalComponent {
  ssoAuthPassed: string;
  loanAccessToken: string;
  consumerConnectSiteId: string;
  authCode: string;
  errorMessage: string;
  infoMessage: string;
  displayBorrowerPortal = false;
  showLoader = true;

  loanAppId: string;
  borrowerId: string;
  eSignEvent: string;
  documentId: string;
  signingRoomId: string;
  internalESign: string;

  constructor(private route: ActivatedRoute, private apppSetting: AppSetting, private apiService: ApiService,
    private authService: AuthenticationService, private userService: UserService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.route.queryParams.pipe(
      filter(params => !!params),
      take(1)
    ).subscribe(queryParam => {
      this.loanAccessToken = queryParam['token'];
      this.ssoAuthPassed = queryParam['ssoAuthPassed'] || '';
      this.consumerConnectSiteId = queryParam['siteId'] || queryParam['siteid'];
      this.authCode = queryParam['authCode'];

      this.internalESign = queryParam['internalESign'];
      this.loanAppId = queryParam['loanAppId'];
      this.borrowerId = queryParam['signerId'];
      this.eSignEvent = queryParam['event'];
      this.documentId = queryParam['documentId'];
      this.signingRoomId = queryParam['signingRoomId'];

      if (this.loanAccessToken || this.loanAppId) {
        if (this.ssoAuthPassed.toLowerCase() !== "true") {
          this.apppSetting.token = null;
          this.apiService.GetSSOLoginUrlForBorrowerPortal().subscribe(
            (data: BorrowerPortalSSOInfo) => {
              this.showLoader = false;
              this.saveToLocalStorage('lsid', data.lsid);
              window.location.href = data.signUrl;
            },
            (error: HttpErrorResponse) => {
              this.showLoader = false;
              console.warn(`Error while getting SSO login URL`, error);
              this.errorMessage = `An error occurred while getting SSO login URL: ${error.message}`;
            });
        }
        else {
          if (!this.apppSetting.token) {
            this.apiService.authenticateWithSSSToken().subscribe(
              (data) => {
                this.authService.handleAuthResponse(data);
                this.userService.setUserLoggedIn(true);
                this.initiateESign();
              },
              (error: HttpErrorResponse) => {
                this.showLoader = false;
                this.errorMessage = `An error occurred while authenticating using SSO Token: ${error.message}`;
              });
          }
          else {
            this.userService.setUserLoggedIn(true);
            this.initiateESign();
          }

        }
      }
      else {
        this.showLoader = false;
      }
    })
  }

  private saveToLocalStorage(paramKey: string, value: string) {
    localStorage.setItem('settings-app-' + paramKey, value);
  }

  get getInfoMessage(): string {
    if (this.errorMessage || this.showLoader) {
      return null;
    }
    switch (this.eSignEvent) {
      case "signing_complete":
        return "You successfully completed eSigning the document!";
      case "viewing_complete":
        return "You successfully completed viewing the document";
      case "session_timeout":
        return "Your session timed out!";
      case "decline":
        return "You declined eSigning the document";
      case "cancel":
        return "You canceled eSigning the document";
      default:
        return "*Contact your loan officer or check email in order to eSign documents";
    }
  }

  private initiateESign(): void {
    if (!!this.internalESign) {
      if (!!this.eSignEvent) {
        this.apiService.HandleSignatureEvent(this.loanAppId, this.documentId, this.borrowerId, ESignerRoleEnum.Borrower, this.signingRoomId, this.eSignEvent).subscribe(
          (data) => {
            this.showLoader = false;
          },
          (error: HttpErrorResponse) => {
            this.showLoader = false;
            console.warn(`Error while handling ESign event`, error);
            this.errorMessage = `Error while handling ESign event: ${error.message}`;
          });
        return;
      }
      else {
        this.apiService.getLoanApp(this.loanAppId, this.borrowerId).subscribe(
          (data) => {
            const loanVm = data.result as ILoanViewModel;
            this.showLoader = false;
            //check if current borrower eConsented and open modal; if accepted, update status in DB and then GetESignUrl
            const currentBorrower = loanVm.transactionInfo.borrowers.find(x => x.borrowerId == this.borrowerId.toLowerCase());
            if (currentBorrower && currentBorrower.eConsent){
              if (currentBorrower.eConsent.consentStatus !== ConsentStatusEnum.Accept) {
              const disclosureStatus = loanVm.transactionInfo.loanApplications[0].disclosureStatusDetails.disclosureStatus;
              const docDelivery = loanVm.transactionInfo.loanApplications[0].docDelivery;

              const eConsentModal = this.dialog.open(ConsentPopUpComponent, {
                width: '820px',
                data: {
                  parentLoanId: loanVm.loanId, borrower: currentBorrower, docDelivery: loanVm.transactionInfo.loanApplications[0].docDelivery,
                  disclosureStatus: disclosureStatus, isLegalDisclaimerConfirmed: loanVm.isLegalDisclaimerConfirmed, encompassBorrowerPairId: loanVm.transactionInfo.loanApplications[0].encompassBorrowerPairId
                },
                disableClose: true, closeOnNavigation: false
              });
              eConsentModal.afterClosed().subscribe(modalResponse => {
                console.log('Modal Closed');
                if (modalResponse && modalResponse.borrowerDeclined) {
                  currentBorrower.eConsent.statusAt = new Date().toISOString();
                  currentBorrower.eConsent.consentStatus = ConsentStatusEnum.Decline;
                  return this.apiService.saveEconsentStatus(currentBorrower, loanVm.loanId, false, disclosureStatus, docDelivery, 1).subscribe(() => {
                    // Re-route user
                    this.logOut();
                  }, () => {
                    // Log out anyway
                    this.logOut();
                  });
                }
                else if (modalResponse.wasEconsentSuccessful) {
                  this.apiService.GetESignUrl(this.loanAppId, this.borrowerId, ESignerRoleEnum.Borrower, encodeURIComponent(window.location.href)).subscribe(
                    (data) => {
                      this.showLoader = false;
                      if (data.response) {
                        window.location.href = data.response;
                      }
                      else {
                        this.logOut();
                      }
                    },
                    (error: HttpErrorResponse) => {
                      this.showLoader = false;
                      console.warn(`Error while getting ESign URL`, error);
                      this.errorMessage = `An error occurred while getting ESign URL: ${error.message}`;
                      this.logOut();
                    });
                }
              },
                (error: HttpErrorResponse) => {
                  this.showLoader = false;
                });
            }
            else{
              this.apiService.GetESignUrl(this.loanAppId, this.borrowerId, ESignerRoleEnum.Borrower, encodeURIComponent(window.location.href)).subscribe(
                (data) => {
                  this.showLoader = false;
                  if (data.response) {
                    window.location.href = data.response;
                  }
                  else {
                    this.logOut();
                  }
                },
                (error: HttpErrorResponse) => {
                  this.showLoader = false;
                  console.warn(`Error while getting ESign URL`, error);
                  this.errorMessage = `An error occurred while getting ESign URL: ${error.message}`;
                  this.logOut();
                });
            }}
          },
          (error: HttpErrorResponse) => {
            this.showLoader = false;
            console.warn(`Error occured while loading the loan.`, error);
            this.errorMessage = `An error occurred while loading the loan: ${error.message}`;
            this.logOut();
          });
      }
    }
    else {
      this.showLoader = false;
      if (!this.loanAccessToken || !this.consumerConnectSiteId) {
        console.warn(`One of required params is missing`);
        this.errorMessage = `One of required params is missing!`;
        return;
      }
      this.displayBorrowerPortal = true;
    }
  }

  private logOut()
  {
    this.apiService.SignOutSSO().subscribe(
      (redirectUrl) => {
        this.userService.setUserLoggedIn(false);
        window.location.href = redirectUrl;
      },
      (error: HttpErrorResponse) => {
        console.warn(`Error while SSO singing out`, error);
        this.errorMessage = `An error occurred while singing out: ${error.message}`;
      });
  }
}
