import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class AppSetting
{
  apiUrl: string;
  lsId: string;

  private _token: string | null = null;
  public get token(): string | null {
    return this._token || localStorage.getItem(AppSettingsProps.token);
  }
  public set token(value: string | null) {
    this._token = value;
    if (!value) {
      localStorage.removeItem(AppSettingsProps.token);
    }
    else {
      localStorage.setItem(AppSettingsProps.token, value);
    }
  }

  private _userAccountId: string | null = null;
  public get userAccountId(): string | null {
    return this._userAccountId || localStorage.getItem(AppSettingsProps.userAccountId);
  }
  public set userAccountId(value: string | null) {
    this._userAccountId = value;
    if (!value) {
      localStorage.removeItem(AppSettingsProps.userAccountId);
    }
    else {
      localStorage.setItem(AppSettingsProps.userAccountId, value);
    }
  }
}

export enum AppSettingsProps {
  token = 'token',
  userAccountId = 'userAccountId'
}
