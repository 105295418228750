export enum ESignerRoleEnum {
  Borrower = 1,
  CoBorrower = 2,
  LO = 3
}

export enum BorrowerContextTypeEnum {
  Borrower = 0,
  CoBorrower = 1,
  Both = 2,
}

export enum ConsentStatusEnum {
  None = 0,
  Accept = 1,
  Decline = 2,
}

export enum BooleanTypeEnum {
  False = 0,
  True = 1,
  Null = -1,
}


export enum DisclosureStatusEnum {
  NotNeeded = 1,
  InitialDisclosureRequired = 2,
  ReDisclosureRequired = 3,
  RequestInProgress = 4,
  DisclosuresCreated = 5,
  DisclosuresFailed = 6,
  WaitingToBeSigned = 7,
  DisclosuresSigned = 8,
  Mailed = 9,
  SignedOffline = 10,
  ExitWorkflow = 11,
  ReDisclosuresInProgress = 12,
  ReDisclosuresCreated = 13,
  ReDisclosuresWaitingToBeSigned = 14,
  ReDisclosuresSigned = 15,
  ReDisclosuresMailed = 16,
  ReDisclosuresSignedOffline = 17,
  ReDisclosuresFailed = 18,
  AutoEFailed = 19,
  AutoECreated = 20,
  WaitingForExitWorkflow = 21,
}

export enum DocDeliveryTypeEnum {
  Mail = 0,
  Electronic = 1,
}
