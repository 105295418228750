export enum AuthenticationStatusEnum {
    Unknown = 0,
    ValidPassword = 1,
    InvalidPassword = 2,
    InvalidUserName = 3,
    InActiveUser = 4,
    AccountLocked = 5,
    InvalidEmailOrSecurityAnswer = 6,
    AccountLockFailed = 7,
    PasswordExpired = 8,
    ResetPasswordOnNextLogin = 9,
  }