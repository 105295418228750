import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BorrowerPortalComponent } from './components/borrower-portal/borrower-portal.component';
import { LoPortalComponent } from './components/lo-portal/lo-portal.component';
import { WelcomeComponent } from './components/welcome/welcome.component';


const routes: Routes = [
  { path: 'borrowerportal', component: BorrowerPortalComponent },
  { path: 'borrowerPortal', component: BorrowerPortalComponent },
  { path: 'loportal', component: LoPortalComponent },
  { path: '', component: WelcomeComponent }
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
