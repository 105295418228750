import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BorrowerPortalComponent } from './components/borrower-portal/borrower-portal.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { AppSetting } from './shared/appSetting';
import { AppConfigService } from './shared/app-config.service';
import { CommonModule } from '@angular/common';
import { LoPortalComponent } from './components/lo-portal/lo-portal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { HttpInterceptorService } from './shared/interceptors/http.interceptor';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule, MatDialogModule, MatIconModule, MatInputModule, MatRadioModule, MatSlideToggleModule } from '@angular/material';
import { SafeHtmlPipe } from './safe-html.pipe';
import { DecodeHtmlEntitiesPipe } from './decode-html-entities.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConsentPopUpComponent } from './components/modals/consent-pop-up/consent-pop-up.component';
import { HintComponent } from './components/modals/hint/hint.component';

@NgModule({
  declarations: [
    AppComponent,
    BorrowerPortalComponent,
    WelcomeComponent,
    LoPortalComponent,
    SafeHtmlPipe,
    DecodeHtmlEntitiesPipe,
    ConsentPopUpComponent,
    HintComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatRadioModule
  ],
  providers: [
    AppSetting,
    AppConfigService,
    CookieService,
    {
    provide: APP_INITIALIZER,
    useFactory: Initialize,
    deps: [AppConfigService, AppSetting],
    multi: true,
    },
    HttpInterceptorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    }],
  bootstrap: [AppComponent],
  entryComponents: [ConsentPopUpComponent, HintComponent]
})
export class AppModule { }

export function Initialize(appConfigService: AppConfigService, appSetting: AppSetting): () => Promise<any> {
  return (): Promise<any> => { return appConfigService.GetAppSettings(appSetting); };
}
