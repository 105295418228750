import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ConsentStatusEnum, DisclosureStatusEnum, DocDeliveryTypeEnum, ESignerRoleEnum } from "../models/enums";
import { IBorrowerViewModel } from "../models/viewmodels";
import { AppSetting } from "./appSetting";

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient, private appSetting: AppSetting) { }

  public GetSSOLoginUrlForBorrowerPortal() {
    const url = `${this.appSetting.apiUrl}/api/security/GetSSOLoginUrlForBorrowerPortal?eSignUrl=${encodeURIComponent(window.location.href)}`;
    return this.http.get<any>(url);
  }

  public GetESignUrl(loanAppId: string, signerId: string, signerRole: ESignerRoleEnum, returnUrl: string) {
    const url = `${this.appSetting.apiUrl}/api/ESigningService/GetSigningRoomUrl?loanAppId=${loanAppId}&signerId=${signerId}&signerRole=${signerRole}&returnUrl=${returnUrl}`;
    return this.http.get<any>(url);
  }

  public HandleSignatureEvent(loanAppId: string, documentId: string, signerId: string, signerRole: ESignerRoleEnum, signingRoomId: string, signEvent: string) {
    const url = `${this.appSetting.apiUrl}/api/ESigningService/HandleSignatureEvent?loanAppId=${loanAppId}&documentId=${documentId}&signerId=${signerId}&signerRole=${signerRole}&signingRoomId=${signingRoomId}&signEvent=${signEvent}`;
    return this.http.get<any>(url);
  }

  public SignOutSSO() {
    let lsid = localStorage.getItem('settings-app-lsid');
    const url = `${this.appSetting.apiUrl}/api/security/SignOutSSO`;
    const relayState = encodeURIComponent(window.location.origin);

    const params = {
      lsid,
      relayState
    };

    return this.http.post(url, null, { params, responseType: 'text' });
  }

  public getCompanyLogo() {
    const url = `${this.appSetting.apiUrl}/api/CompanyProfile/BranchLogo/`;
    return this.http.get<any>(url);
  }

  public authenticateAndGrantOAuthToken(username: string, password: string) {
    const url = `${this.appSetting.apiUrl}/api/SecurityService/AuthenticateAndGrantConsumerSiteOAuthToken?serviceAccountUserAccountId=123456`;

    const formData = this.getFormDataForAuth();
    formData.append('username', username);
    formData.append('password', password);

    return this.http.post<any>(url, formData, { observe: 'response' });
  }

  private getFormDataForAuth(): FormData {
    const formData = new FormData();
    formData.append('client_id', `MmlOAuthClientDescription`);
    formData.append('redirect_uri', `https://qa01concierge.newleaflending.com`);
    formData.append('scope', `scope-mml-lc`);
    formData.append('response_type', `token`);
    return formData;
  }

  authenticateWithSSSToken() {
    const formData = this.getFormDataForAuth();
    const endpoint = `${this.appSetting.apiUrl}/api/security/AuthenticateWithSSOToken`;
    return this.http.post<any>(endpoint, formData, { observe: 'response', withCredentials: true });
  }

  getLoanApp(loanAppId: string, borrowerId: string) {
    const formData = this.getFormDataForAuth();
    const endpoint = `${this.appSetting.apiUrl}/api/Loan/EagerLoadESignPortal?loanAppid=${loanAppId}&borrowerId=${borrowerId}&userAccountId=-1`;
    return this.http.get<any>(endpoint);
  }

  /**
 * Save the eConsent status for borrower
 * @param borrower
 * @param parent loan id
 * @param acceptConsent Is eConsent being accepted
 * @param disclosureStatus Disclosure status of the loan
 * @param docDelivery DocDelivery of the loan
 * @param numberOfConsentingBorrowers Number of borrowers that accept eConsent
 */
  saveEconsentStatus(borrower: IBorrowerViewModel, loanId: string, showCommunicationConsentText: boolean, disclosureStatus: DisclosureStatusEnum, docDelivery: DocDeliveryTypeEnum, numberOfConsentingBorrowers: number): Observable<any> {
    const callTimestamp = new Date().getTime().toString();
    const url = `${this.appSetting.apiUrl}/api/ConsumerSiteService/BorrowerEConsent`;
    const params = {
      callTimestamp,
      userId: 'User',
    };

    if (borrower.eConsent) {
      borrower.eConsent.source = window.location.origin;
    }

    // Create a API request for each borrower that needs to be saved
    return this.http.post<any>(url, {
      borrowerViewModel: borrower,
      documentDeliveryType: this.getDocDelivery(disclosureStatus, docDelivery, numberOfConsentingBorrowers),
      loanId: loanId,
      communicationConsentTextAvailable: showCommunicationConsentText
    }, { params }).pipe(map((response) => {
      if (response && response.errorMsg) {
        throw new Error(response.errorMsg);
      } else {
        return response;
      }
    }));
  }

  updateEConsentDisclosureTrackingData(borrower: IBorrowerViewModel, encompassBorrowerPairId: string) {
    const endpoint = `${this.appSetting.apiUrl}/api/ConsumerSiteService/UpdateEConsentDisclosureTrackingData`;
    return this.http.post<any>(endpoint, {borrowerViewModel: borrower, encompassBorrowerPairId: encompassBorrowerPairId});
  }

  public getESignPortalonfiguration() {
    let lsid = localStorage.getItem('settings-app-lsid');
    const url = `${this.appSetting.apiUrl}/api/Configuration/ESignPortal?leadSourceId=${lsid}`;
    return this.http.get<any>(url);
  }

  /**
   * Set docDelivery in order to disclsoure status
   * @param disclosureStatus Disclosure status of the loan
   * @param docDelivery DocDelivery of the loan
   * @param numberOfConsentingBorrowers Number of borrowers that accept eConsent
   */
  private getDocDelivery(disclosureStatus: DisclosureStatusEnum, docDelivery: DocDeliveryTypeEnum, numberOfConsentingBorrowers: number): DocDeliveryTypeEnum {
    const disclosureCreatedEnums = [DisclosureStatusEnum.DisclosuresCreated, DisclosureStatusEnum.DisclosuresSigned, DisclosureStatusEnum.WaitingToBeSigned];

    if (disclosureCreatedEnums.some(x => x == disclosureStatus)) {
      return docDelivery;
    } else {
      return numberOfConsentingBorrowers > 0 ? DocDeliveryTypeEnum.Electronic : DocDeliveryTypeEnum.Mail
    }
  }
}
